@font-face {
  font-family: "DMSans";   /*Can be any text*/
  src: local("DMSans"), url("../assets/DMSans-Regular.woff2") format("woff2");
}

.EspaceFormulaireLogin .Titre {
  color: #000000;
  margin-bottom: 20px ;
}
.EspaceFormulaireLogin fieldset {
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
  padding: 0;
}
.formulaire {
  display: flex;
  align-items: space-between;
  justify-content: center;
}
.EspaceFormulaireLogin form{
  padding-bottom: 40px;
  width:80%;
}
.EspaceFormulaireLogin input{
  height: 40px;
}
.EspaceFormulaireLogin input,  .EspaceFormulaireLogin textarea{
  border: 1px solid black; /*needed to override the default style for <input> */
  border-radius: 12px;

  color: #000000;
  font-family: DMSans;
  font-size: 20px;
  padding-left: 20px ;
}
.EspaceFormulaireLogin input::placeholder {
  color: #858585;
  font-family: DMSans;
  font-size: 20px;
}

.formulaire-contact-nomprenom{
  display: flex;
  justify-content: space-between;
}
.formulaire-contact-nom {
  width: 36vw;
}
.formulaire-contact-prenom {
  width: 36vw;
}